<template>
  <div>
    <div id="appPage">
      <div id="left" class="appPageParts">
        <div class="content">
          <h1>
            <span>DOWNLOAD <br />YOUR APP </span><br />NOW
          </h1>
        </div>
      </div>
      <div id="right" class="appPageParts">
        <div class="content">
          <img src="@/assets/img/ISRT.png" id="appPic" />
          <div id="appButtonContainer">
            <img src="@/assets/img/AndroidIcon.png" id="android" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "isrtApp"
};
</script>

<style lang="scss" scoped>
#appPage {
  width: 100%;
  display: flex;
  margin: 30px 0 0;
  flex-direction: column;
  @include media-md {
    flex-direction: row;
  }
  @include media-md {
    margin: 50px 0 0;
  }
  @include media-xl {
    margin: 70px 0 0;
  }
  @include media-xxl {
    margin: 90px 0 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    @include media-md {
      display: unset;
    }
  }
  .appPageParts {
    width: 100%;
    justify-content: center;
    h1 {
      //font-weight: 600;
      color: $color1;
      font-size: 2.5rem;
      @include media-md {
        font-size: 3.3rem;
      }
      @include media-lg {
        font-size: 4.1rem;
      }
      @include media-xl {
        font-size: 5rem;
      }
      span {
        color: $grey;
        font-weight: 400;
        letter-spacing: -0.09rem;
        text-align: center !important;
        @include media-md {
          letter-spacing: -0.1rem;
        }
        @include media-lg {
          letter-spacing: -0.3rem;
        }
      }
    }
    @include media-md {
      width: 50%;
      flex-grow: 1;
    }
  }
  #left {
    display: flex;
    align-items: left;
    flex-direction: column;
    text-align: center;
    @include media-md {
      text-align: left;
    }
  }
  #right {
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: flex-start;
    @include media-lg {
      padding: 0 25px 0 5px;
    }
    @include media-xl {
      padding: 0 45px 0 5px;
    }
    @include media-xxl {
      padding: 0 105px 0 5px;
    }
    #appPic {
      height: 330px;
    }
    #appButtonContainer {
      padding: 20px 0;
      img {
        width: 90px;
      }
    }
  }
}
</style>
